import * as PentestThumbnail from '../images/pentest.jpg'
import * as SfeThumbnail from '../images/sfe.jpg'

type App = {
  title: string
  description: string
  thumbnail: any // eslint-disable-line 
  href: string | undefined
}

type Features = {
  showOnlyEnabledApps: boolean
}

type StatusMessage = {
  type: 'info' | 'error' | 'warning' | 'success'
  message: string
}

export const APPS: { [key: string]: App } = {
  'app:sfe': {
    title: 'Secure File Exchange',
    description: 'Create repositories and share files',
    thumbnail: SfeThumbnail,
    href: process.env.CSP_SFE_BASE_URL,
  },
  'app:pentest': {
    title: 'Pentest Service',
    description: 'Upload reports and manage findings',
    thumbnail: PentestThumbnail,
    href: process.env.CSP_PENTEST_BASE_URL,
  },
}

export const STATUS_MESSAGES: { [key: string]: StatusMessage } = {
  insufficientPermissions: {
    type: 'info',
    message: "You don't have permissions to view this page.",
  },
  serverError: {
    type: 'error',
    message: 'An error occurred. Please try again.',
  },
}

export const FEATURES: Features = {
  showOnlyEnabledApps: JSON.parse(
    process.env.CSP_PORTAL_FEATURE_SHOW_ONLY_ENABLED_APPS || 'false',
  ),
}
